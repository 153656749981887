<template>
  <div
    ref="el"
    class="flex flex-col items-center content-center w-full bg-white pb-xl md:rounded-minilu-xl"
    :class="{
      'pt-[53px]': !(dialogStore.headline && isInDialog),
      'pt-sm': dialogStore.headline && isInDialog,
    }"
  >
    <div
      v-if="headline && !(dialogStore.headline && isInDialog)"
      class="mb-md mt-sm"
    >
      <HeadlineDynamic :headline="t(headline)" level="h1" has-decoration />
    </div>
    <div v-if="image" class="flex items-center justify-center w-full mb-md">
      <img
        :src="getImageSrc(image)"
        loading="lazy"
        class="max-h-[196px] h-[20vh] min-h-[100px]"
      />
    </div>

    <div v-if="subHeadline" class="mb-xs">
      <HeadlineDynamic :headline="t(subHeadline)" level="h4" has-decoration />
    </div>

    <div class="text-center mb-md">
      <template v-for="(text, i) in texts" :key="i">
        <p v-if="text">
          {{ t(text) }}
        </p>
        <br v-else />
      </template>
    </div>

    <ButtonBasic
      v-if="cta"
      :label="t(cta)"
      :type="EButtonType.BUTTON"
      :btn-style="EButtonStyle.CTA"
      bg-color="var(--minilu-btn-base)"
      full-width
      :loading-animation="isLoading"
      :disabled="isLoading"
      @click="$emit('clickCta')"
    />

    <div
      v-if="hasClose"
      class="cursor-pointer text-minilu-base mt-md font-pero text-18"
      @click="$emit('close')"
    >
      <FaIcon icon-class="fas fa-xmark" />
      <span class="anim__underline-link minilu-anim-link">{{
        t('formFields.success.close')
      }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import HeadlineDynamic from '~~/src/components/components/core/headline-dynamic/minilu/headline-dynamic.vue';
import ButtonBasic from '~~/src/components/components/core/basic-button/minilu/basic-button.vue';
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import FaIcon from '~/components/fa-icon.vue';
import { useDialogStore } from '@/stores/useDialogStore';

const { t } = useTrans();

const props = defineProps({
  headline: {
    type: String,
    default: '',
  },
  subHeadline: {
    type: String,
    default: '',
  },
  cta: {
    type: String,
    default: '',
  },
  texts: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  image: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasClose: {
    type: Boolean,
    default: false,
  },
});

defineEmits<{
  (e: 'clickCta'): void;
  (e: 'close'): void;
}>();

const dialogStore = useDialogStore();
dialogStore.setHeadline(props.headline);

const el = ref<HTMLElement | null>(null);
const isInDialog = useIsInDialog(el);

function getImageSrc(image: string) {
  return useImages().getImageSrc(image);
}
</script>
